import React from "react";
import { HttpMethod, HttpWrapper } from "src/helpers/httpWrapper";
import { URLWrapper } from "src/networked-shared-interfaces/url-wrapper";
import { toastService } from "src/services";
import { ExtendTrialForm } from "./ExtendTrialForm";
import moment from "moment";
import InviteLink from "./InviteLink";

class OtherActionsButton extends React.Component<
  { communityId: string , adminId : string , isCommunity : boolean },
  {
    otherOptionsVisible: boolean;
    trialEndTime: number;
    subscriptionStatus: string;
    loading: boolean;
    openModal: boolean;
    fetchingURL: boolean;
	openShowInviteModal : boolean
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      otherOptionsVisible: false,
      trialEndTime: 0,
      subscriptionStatus: "",
      loading: false,
      openModal: false,
      fetchingURL: false,
      openShowInviteModal: false,
    };
  }

  toggleModal(isOpen: boolean) {
    this.setState({ openModal: isOpen });
  }

  getSubscriptionDetails = () => {
    this.setState({ loading: true });
    this.toggleModal(true);

    HttpWrapper.callUrl<any, any>(
      URLWrapper.urls.stripe.getSubscriptionDetails,
      HttpMethod.GET,
      { communityId: this.props.communityId },
      {}
    )
      .then((data) => {
        this.setState({
          trialEndTime: data.trialEnd,
          subscriptionStatus: data.status,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        toastService.error("Failed to load subscription data");
      });
  };

  toggleOtherOptions = () => {
    this.setState((prev) => ({
      otherOptionsVisible: !prev.otherOptionsVisible,
    }));
  };

  getSessionUrl = () => {
    this.setState({ fetchingURL: true });
    HttpWrapper.callUrl<any, any>(
      URLWrapper.urls.stripe.checkoutSessionUrl,
      HttpMethod.GET,
      { communityId: this.props.communityId },
      {}
    )
      .then((response: any) => {
		if(response.customerSessionUrl){
			window.navigator.clipboard.writeText(response.customerSessionUrl);
			toastService.success("Copied to clipboard");
		} else {
			toastService.error("Failed to find the plan");
		}
		this.setState({ fetchingURL: false });
      })
      .catch((error) => {
        toastService.error(error.message);
        this.setState({ fetchingURL: false });
      });
  };

  generateReport = () => {
	this.setState({ fetchingURL: true });
    HttpWrapper.callUrl<any, any>(
      URLWrapper.urls.platformAdmin.generateWeeklyReport,
      HttpMethod.POST,
      { communityId: this.props.communityId },
      {},
	  { responseType: 'blob' }
    )
      .then((_response: any) => {
		if(_response?.message) {
			toastService.error(_response.message);
		}
		const [rn, frn] = [
			new Date(),
			moment(new Date()).format('DD-MM-YYYY'),
		  ];
		const href = URL.createObjectURL(new Blob([_response], { type: "text/csv" }))
		const link = document.createElement('a');
		link.href = href;
		link.setAttribute('download', `wba-user-${rn + '-' + frn}.csv`); //or any other extension
		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		URL.revokeObjectURL(href);
	})
      .catch((error) => {
        toastService.error(error.message);
	}).finally(() => {
		  this.setState({ fetchingURL: false });
	  });
  }

  createInviteLink = () => {
	this.setState({ openShowInviteModal: true });
  }

  render() {
    return (
      <>
        <td className='custom-action' onClick={() => this.toggleOtherOptions()}>
          &#8286;
          {this.state.otherOptionsVisible && (
            <ul className='custom-unordered'>
              <li
                className='custom-unordered-item btn w-100'
                onClick={() => this.getSessionUrl()}
              >
                Create Setup Link
              </li>
              <li
                className='custom-unordered-item btn w-100'
                onClick={() => this.getSubscriptionDetails()}
              >
                Extend Trial
              </li>
              <li
                className='custom-unordered-item btn w-100'
                onClick={() => this.generateReport()}
              >
                Weekly Report
              </li>
			{this.props.isCommunity && <li
                className='custom-unordered-item btn w-100'
                onClick={() => this.createInviteLink()}
              >
                Create Invite Link
              </li>}
            </ul>
          )}
          {this.state.fetchingURL && <ul className='custom-unordered'>
              <li
                className='custom-unordered-item btn w-100'
              >
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </li>
            </ul>}
          <ExtendTrialForm
			subscriptionStatus={this.state.subscriptionStatus}
            communityId={this.props.communityId}
            trialEndTime={this.state.trialEndTime}
            loading={this.state.loading}
            isOpen={this.state.openModal}
            onClose={() => this.toggleModal(false)}
          />
		  {this.state.openShowInviteModal && this.props.isCommunity &&
		  <InviteLink
		  	onClose={() => this.setState({openShowInviteModal : !this.state.openShowInviteModal}) }
			isOpen={this.state.openShowInviteModal}
			communityId = {this.props.communityId}
			adminId = {this.props.adminId}
		  />}
        </td>
      </>
    );
  }
}

export { OtherActionsButton };
