import React, { useState, useEffect } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import { HttpMethod, HttpWrapper } from 'src/helpers/httpWrapper';
import { VCommunityTag, ICommunityTagRequest, ICommunityTagResponse} from 'src/networked-shared-interfaces/global-routes/tag-routes';
import { URLWrapper } from 'src/networked-shared-interfaces/url-wrapper';
import { userTags } from 'src/networked-shared-interfaces/constants/userTags.constants';
import { IInvitationUrlGetResponse} from 'src/networked-shared-interfaces/invitation-url-routes/invitations-url-routes';
import { toastService } from 'src/services';
import { VCommunityInvitation } from 'src/networked-shared-interfaces/interfaces-phase2/platform-admin/platform-admin';
import { IInvitationUrlVerifyGetResponse } from "src/networked-shared-interfaces/invitation-url-routes/invitations-url-routes";
import ActiveLinkIcon from "../../../assets/images/activeLinkIcon.png";
import { IInvitationLink, IInvitationUrlUpdateRequest, VUpdateInvitationUrl } from 'src/networked-shared-interfaces/invitation-url-routes/invitations-url-routes';

interface IProps {
	onClose: Function,
	isOpen: Boolean,
	communityId?: string,
	adminId? : string
}

const inviteLinkDate = [
	{
		key: '1 day',
		value: 1,

	},
	{
		key: '7 days',
		value: 7
	},
	{
		key: '30 days',
		value: 30
	},
	{
		key: 'Never expires',
		value: -1
	}
];

const inviteLinkUserLimit = [
	{
		key: '1 Users',
		value: 1
	},
	{
		key: '5 Users',
		value: 5
	},
	{
		key: '10 Users',
		value: 10
	},
	{
		key: '25 Users',
		value: 25
	},
	{
		key: '50 Users',
		value: 50
	},
	{
		key: '100 Users',
		value: 100
	},
	{
		key: 'No Limit',
		value: -1
	},
];

const InviteLink: React.FC<IProps> = (props) => {
	const { isOpen, onClose, communityId , adminId } = props;

	const [affiliatedTagsList, setAffiliatedTagsList] = useState([])
	const [selectedAffiliatedTags, setSelectedAffiliatedTags] = useState([])
	const [userLimit, setUserLimit] = useState<object>([{
		key: 'No Limit',
		value: -1
	}])
	const [expiryPeriod, setExpiryPeriod] = useState<object>([{
		key: '30 days',
		value: 30
	}])
	const [creatingLink, setCreatingLink] = useState(false)

	const [recentLink, _recentLink] = useState<any>(null);
	const [updatingLinkLoader, setUpdatingLinkLoader] = useState<boolean>(false);
	const [recentLinkLoader, setRecentLinkLoader] = useState<boolean>(false);

	const copyToClipBoard = (data) => {
		const clipboard = navigator.clipboard;
		if (clipboard) {
			clipboard.writeText(data);
			return;
		}
	}

	useEffect(() => {
		getAffiliatedTags()
		getRecentLink()
	}, [])

	const getAffiliatedTags = () => {
		HttpWrapper.callUrl<ICommunityTagRequest, ICommunityTagResponse>(URLWrapper.urls.globalRoutes.communityTags, HttpMethod.GET, { communityId: communityId }, VCommunityTag)
			.then(response => {
				console.log(" response : --> ", response);

				const data = response.map(item => { return { value: item.tagId, label: item.name, ...item } });
				const findMember = data.find((member) => member.label === userTags.Member);
				const defaultSelectOption = findMember?.label ? findMember : data[0];
				setSelectedAffiliatedTags([defaultSelectOption])
				console.log(" defaultSelectOption : ", defaultSelectOption);

				setAffiliatedTagsList(data);
			}).catch(error => {
				console.log("getAffiliatedTags - error: ", error);
			});
	}

	const getRecentLink = () => {
		setRecentLinkLoader(true)
		HttpWrapper.callUrl<any, IInvitationUrlVerifyGetResponse>(URLWrapper.urls.platformAdmin.invitationRecent, HttpMethod.GET,{
			communityId,
			adminId
		}, {}, {}).then((response) => {

			// console.log(" response from recent link ----> :: ", response);
			
			// @ts-ignore
			if (response?.isLinkAvailable) {
				// @ts-ignore
				const link = response?.invitationLink;
				_recentLink(link);

				const asInvite = link?.inviteRole;
				const expireLink = inviteLinkDate.find(limit => limit.value === link?.expiryDays);
				const userLimit = inviteLinkUserLimit.find(limit => limit.value === link?.limit);

				setExpiryPeriod([{
					key: expireLink?.key,
					value: expireLink?.value
				}]);
				setUserLimit([{
					key: userLimit?.key,
					value: userLimit?.value
				}]);
				setSelectedAffiliatedTags([{
					value : asInvite._id.toString() ,
					name : asInvite.name,
				}]);

			} else {
				_recentLink('');
			}
		}).catch(error => {
			console.log("Generate Recente Link list Error", error)
		}).finally(() => {
			setRecentLinkLoader(false)
		})
	}

	const createInvitationLink = () =>{

		if(!userLimit[0]?.value || !selectedAffiliatedTags[0]?.value || !expiryPeriod[0]?.value ){
			let text = '';
			if(!userLimit[0]?.value) text = 'Maximum Number of Users '
			if(!selectedAffiliatedTags[0]?.value) text = 'Invite as'
			if(!expiryPeriod[0]?.value) text = 'Expire Period'

			toastService.error(`Please select from available options in ${text} dropdown`)
			return
		}

		let changesObj = {
			expiryDays: expiryPeriod[0]?.value,
			limit: userLimit[0]?.value,
			inviteRole: selectedAffiliatedTags[0]?.tagId
		};

		setCreatingLink(true)
		HttpWrapper.callUrl<any, IInvitationUrlGetResponse>(URLWrapper.urls.platformAdmin.invitation, HttpMethod.POST, {
			communityId,
			adminId,
			...changesObj
		}, VCommunityInvitation).then((response) => {
			// @ts-ignore
			_recentLink(response);
			console.log(" resposne created invitation url ----> : ", response);
			
			toastService.success("Link Generated Succesfully");
		}).catch(error => {
			console.log("Get generate link Error", error)
		}).finally(() => {
			setCreatingLink(false)
		});
	}

	const updateLink = () =>{
		setUpdatingLinkLoader(true)
		
		let oldObj = {
			expiryDays: recentLink?.expiryDays,
			limit: recentLink?.limit,
			inviteRole: recentLink?.inviteRole?._id
		};

		if(!userLimit[0]?.value || !selectedAffiliatedTags[0]?.value || !expiryPeriod[0]?.value ){
			let text = '';
			if(!userLimit[0]?.value) text = 'Maximum Number of Users '
			if(!selectedAffiliatedTags[0]?.value) text = 'Invite as'
			if(!expiryPeriod[0]?.value) text = 'Expire Period'

			toastService.error(`Please select from available options in ${text} dropdown`)
		}

		let newObj = {
			expiryDays: expiryPeriod[0]?.value,
			limit: userLimit[0]?.value,
			inviteRole: selectedAffiliatedTags[0]?.tagId
		};
		
		let changes = {};
		Object.keys(newObj).forEach((key) => {
			if (oldObj.hasOwnProperty(key) && oldObj[key] !== newObj[key]) {
				changes[key] = newObj[key];
			}
		});

		const updatePayload = {
			invitationLinkId: recentLink?.id || recentLink['_id'],
			...changes
		}

		console.log("Payload for update", recentLink, updatePayload);

		HttpWrapper.callUrl<IInvitationUrlUpdateRequest, any>(URLWrapper.urls.platformAdmin.invitationUpdate, HttpMethod.PUT, {
			...updatePayload
		}, VUpdateInvitationUrl,
			{}).then((response) => {	
				toastService.success('Invitation Link updated Successfully');
			}).catch(error => {
				toastService.error(error?.data?.message || "User limit has already been extended");
			}).finally(() => {
				setUpdatingLinkLoader(false)
			});
	}

	if (!isOpen) return <></>;

	return (
		<div
			className='modal d-block'
			data-show='true'
			style={{ background: "rgba(0,0,0,0.4)" }}
			role='dialog'
		>
			<div className='modal-dialog' role='document'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title'>Create Invite Link</h5>
						<button
							type='button'
							className='close'
							data-dismiss='modal'
							aria-label='Close'
							onClick={() => onClose()}
						>
							<span aria-hidden='true'>&times;</span>
						</button>
					</div>
					{!recentLinkLoader ?
						<>
							<div className='modal-body'>
								<div className='input-group mb-3'>
									<div className="row w-100">
										<div className="form-group col-md-6">
											<label htmlFor="userRole">Invite as:</label>
											<Typeahead
												options={affiliatedTagsList}
												id="userRole"
												style={{ border: "none" }}
												selected={selectedAffiliatedTags}
												labelKey={'name'}
												filterBy={['name']}
												onChange={setSelectedAffiliatedTags}
												emptyLabel="No User Role found"
												multiple={false}

											/>
										</div>
										<div className="form-group col-md-6">
											<label htmlFor="linkExpiry">Link set to expire after:</label>
											<Typeahead
												options={inviteLinkDate}
												id="linkExpiry"
												style={{ border: "none" }}
												selected={expiryPeriod}
												labelKey={'key'}
												filterBy={['key']}
												onChange={setExpiryPeriod}
												emptyLabel="No Expiry option found"
												multiple={false}
											/>
										</div>
									</div>
									<div className="row w-100">
										<div className="form-group col-md-12">
											<label htmlFor="inviteUser">Maximum number of users:</label>
											<Typeahead
												options={inviteLinkUserLimit}
												id="inviteUser"
												style={{ border: "none" }}
												selected={userLimit}
												labelKey="key"
												filterBy={['key']}
												onChange={setUserLimit} // Ensure it's a string
												emptyLabel="No limit found"
												multiple={false}
											/>
										</div>
									</div>
									<div className="row box"  onClick={() => {
											if(recentLink){
												copyToClipBoard(recentLink?.shortenLink) ; 
												toastService.success('Link Copied to Clipboard ') 
											}
										}}>
										<img src={ActiveLinkIcon} alt="link icon" className='mr-2' />
										{/* @ts-ignore */}
										{recentLink ? <a href='#' >{recentLink?.shortenLink}</a> : "No existing link found"}
									</div>
								</div>
							</div>
							<div className='modal-footer'>

							{(creatingLink || updatingLinkLoader) && 
								<div className='spinner-border' role='status'>
									<span className='sr-only'>Loading...</span>
								</div>
							}

							<button
								type='button'
								className='btn btn-secondary'
								data-dismiss='modal'
								onClick={() => onClose()}
							>
								Close
							</button>
							{recentLink?.shortenLink && <button
								type='button'
								disabled={updatingLinkLoader}
								className='btn btn-success'
								onClick={() => updateLink()}
							>
								Update link
							</button>}

							<button
								type='button'
								disabled={creatingLink}
								className='btn btn-primary'
								onClick={() => createInvitationLink()}
							>
								Create New link
							</button>

							</div>
						
						</>
						:
						<div className='d-flex justify-content-center'>
							<div className='spinner-border' role='status'>
								<span className='sr-only'>Loading...</span>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default InviteLink