import { UserRoleType } from "../constants/user-role-type";
import { ValidationUtility } from "../validation-utility";
import { IValidatorType } from "../validator-interface";

//Req [GET] /tags/all
export interface ITagRequest { }

//Val [GET] /tags/all
export const VTag: IValidatorType<ITagRequest> = {}

//Res [GET] /tags/all
export type ITagResponse = {
	tagId: string,
	name: string,
	description?: string
}[]



//--------------------------------------------------------



//Req [GET] /tags/community
export type ICommunityTagRequest = {
	communityId?: string
}

//Val [GET] /tags/community
export const VCommunityTag: IValidatorType<ICommunityTagRequest> = {
	communityId: { function: ValidationUtility.validateObjectId, params: [], required: false }
}

//Res [GET] /tags/community
export type ICommunityTagResponse = {
	tagId: string,
	name: string
	description?: string,
	type: UserRoleType
	// default: boolean
}[]