import { ModelStatus } from "../constants/model-status";
import { ValidationUtility } from "../validation-utility";
import { IValidatorType } from "../validator-interface";

export interface IInvitationLink {
	id?: string
	community: any;
	createdAt: number;
	updatedAt: number;
	expiryDays: number;
	expiredAt: number;
	limit: number;
	inviteRole: any;
	token?: string;
	shortenLink: string;
	status: ModelStatus;
	creator: any;
	isLinkExpired?: boolean;
	userCount?: number;

	monetizationPlans?: string[]
}

//Req [GET] /recent - get recent Invitation URL Link
export interface IIRecentInvitationLink {
	invitationLink?: IInvitationLink,
	isLinkAvailable: boolean
}

export interface IInvitationLinkStatus {
	id: string,
	name: string,
	title: string,
	role: string,
	image?: string
}

//Req [POST] /invitation-url - This is for create invitation-url
export interface IInvitationUrlUpdateRequest {
	invitationLinkId: string,
	expiryDays?: number,
	limit?: number,
	inviteRole?: string,
	monetizationPlanIds?: string[],
	status?: ModelStatus
}

let validStatus = [ModelStatus.Active, ModelStatus.Deactivate];
//Val [POST] /invitation-url
export const VUpdateInvitationUrl: IValidatorType<IInvitationUrlUpdateRequest> = {
	invitationLinkId: { function: ValidationUtility.validateObjectId, params: [], required: true },
	expiryDays: { function: ValidationUtility.validateNumber, params: [1, -1], required: false },
	limit: { function: ValidationUtility.validateNumber, params: [1, -1], required: false },
	inviteRole: { function: ValidationUtility.validateObjectId, params: [], required: false },
	monetizationPlanIds: { function: ValidationUtility.validateObjectArrayId, params: [], required: false },
	status: { function: ValidationUtility.validateModelStatus, params: [validStatus], required: false }
}

//Req [GET] /invitation-url
export interface IInvitationUrlGetRequest {
	start: number,
	count: number,
}

//Val [GET] /invitation-url
export const VInvitationGet: IValidatorType<IInvitationUrlGetRequest> = {
	start: { function: ValidationUtility.validateNumber, params: [], required: true },
	count: { function: ValidationUtility.validateNumber, params: [], required: true },
}

//Req [GET] /invitation-link status
export interface IInvitationUrlStatusGetRequest {
	invitationLinkId: string,
	start: number,
	count: number,
	keyword?: string
}

//Val [GET] /invitation-link status
export const VInvitationStatusGet: IValidatorType<IInvitationUrlStatusGetRequest> = {
	invitationLinkId: { function: ValidationUtility.validateObjectId, params: [], required: true },
	start: { function: ValidationUtility.validateNumber, params: [], required: true },
	count: { function: ValidationUtility.validateNumber, params: [], required: true },
	keyword: { function: ValidationUtility.validateStringFields, params: [1, -1], required: false }
}

//Req [GET] /invitation-link status
export interface IInvitationUrlStatusGetResponse {
	start: number,
	count: number
	nextStart?: boolean
	content: Array<IInvitationLinkStatus>,
	totalCount: number
}

export interface IInvitationUrlGetResponse {
	start: number,
	count: number
	nextStart?: boolean
	invitationsLinks: Array<IInvitationLink>
}

export interface IInvitationUrlVerifyGetResponse {
	isLinkExpired: boolean,
	inviterName: string
}


//Req [GET] /community/invitation/public
export interface IInvitationPublicGetRequest {
	invitationLinkId: string,
}
//Val [GET] /community/invitation/public
export const VInvitationPublicGet: IValidatorType<IInvitationPublicGetRequest> = {
	invitationLinkId: { function: ValidationUtility.validateObjectId, params: [], required: true }
}
//Val [GET] - Response /community/invitation/public
export interface IInvitationPublicGetResponse {
	invitationLinkId: string,
	inviterName: string,
	communityName: string,
	androidUrl: string,
	iosUrl: string
	communityImage?: string,
}
